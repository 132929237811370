import React, { PureComponent, Fragment } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { get as _get } from 'lodash';
import accimage from '../../../assets/images/acclogo.png';
import boxplotimage from '../../../assets/images/boxplot.png';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import moment from 'moment-timezone';
import FilterSection from './partials/FilterSection';
import BannerSection from './partials/BannerSection';
import CourseSection from './partials/CourseSection';



import {
  fetchParentStudent,
  fetchStudentProfile,
  fetchSubjectAnalysis,
  overrideSubjectAnalysis,
} from '../../../actions/acc/ParentDashboard.action';

const preloaderGif =
  `${process.env.REACT_APP_S3_BUCKET_URL}/tentacle/public/tentacle_loader.gif`;
class ParentProfile extends PureComponent {
  constructor(props) {
    super(props);
    const queryx = new URLSearchParams(this.props.location.search);
    this.fetchedItems = [];
    this.state = {
      isFilterTermsLoaded: false,
      isFilterSemestersLoaded: false,
      isFilterStudentsLoaded: false,
      filterTermValue: 0,
      filterSemesterValue: 0,
      filterStudentValue: 0,
      filterYearLevelValue: 0,
      filterTerms: [],
      filterStudents: [],
      filterSemesters: [],
      courseAnalysisData: [],
      tableData: [],
      logged_in: queryx.get('token'),
      token_with_id: queryx.get('t'),
      token: `Bearer ${queryx.get('t')}`,
      profile: queryx.get('profile'),
      isFirstTimeLoad: true,
      isCourseAnalysisLoading: true,
      role_access: queryx.get('access'),
      filterChanged: Date.now(),
      isSubjectsLoading: false,
    };
  }

  componentDidMount() {
    this.filterTerms();
    window.addEventListener("scroll", () => {
      window.requestAnimationFrame(() => {
        this.fetchItemsInViewport();
      })
    })
  };

  componentDidUpdate(prevProps) {
    const {
      filterData,
      filterTerms,
      isDashboardLoading,
      currentStudent,
      isCurrentStudentLoading,
      subjectAnalysis,
      isCourseAnalysisLoaded,
    } = this.props;

    if (
      JSON.stringify(prevProps.filterData) !==
      JSON.stringify(filterData)
    ) {
       
      this.setState({
        filterData,
        filterTerms,
      }, () => {
  
        if (filterTerms.length) {
          this.setState(
            {
              isFilterTermsLoaded: true,
              filterTermValue: this.state.filterTerms[0].id,
            },
            () => {
              this.changeTermValue(this.state.filterTermValue);
            },
          );
          return;
        }
        this.handleNoDataInFilters();
      });
    }

    if (prevProps.isDashboardLoading && !isDashboardLoading) {
      this.setState({
        isFilterTermsLoaded: true,
      });
    }

    if (
      JSON.stringify(prevProps.currentStudent) !==
      JSON.stringify(currentStudent)
    ) {
      this.setState({
        studentName: _get(currentStudent, 'name', ''),
        avatar: _get(currentStudent, 'avatar_url', ''),
      });
    }

    if (prevProps.isCurrentStudentLoading && !isCurrentStudentLoading) {
      this.setState({
        isStudentNameLoaded: true,
      });
    }

    if (
      JSON.stringify(prevProps.subjectAnalysis) !==
      JSON.stringify(subjectAnalysis)
    ) {
      this.setState({
        courseAnalysisData: Object.values(subjectAnalysis),
      }, () => {

        if (this.state.courseAnalysisData.length) {
          this.drawSubjectAnalysis();
        }
      });

      const isSubjectsLoading = !!Object.values(
        subjectAnalysis
      ).filter(subject => {
        return subject.isLoading
      }).length;

      this.setState({
        isSubjectsLoading,
      });
    }

    if (prevProps.isCourseAnalysisLoaded && !isCourseAnalysisLoaded) {
      this.setState({
        isCourseAnalysisLoaded: true,
        isCourseAnalysisLoading: false,
      });
    }
  };

  filterTerms() {
    this.setState({
      isFilterYearLevelsLoaded: false,
      isFilterCoursesLoaded: false,
      isFilterStudentsLoaded: false,
      isFilterSemestersLoaded: false,
      isFilterTermsLoaded: false,
      isGoButtonDisabled: false,
    });

    const params = {
      logged_in: this.state.logged_in,
      access: this.state.role_access,
    };
    const { fetchParentStudent } = this.props;
    fetchParentStudent(params, this.state.token);
  };

  handleNoDataInFilters() {
    this.setState(
      {
        isFilterTermsLoaded: true,
        isFilterSemestersLoaded: true,
        isFilterStudentsLoaded: true,
        isStudentNameLoaded: true,
        filterTerms: [{ name: 'No Data', id: '' }],
        filterSemesters: [{ name: 'No Data', id: '' }],
        filterStudents: [{ name: 'No Data', id: '' }],
      },
      () => {
        this.setState({
          isCourseAnalysisLoaded: false,
          isCourseAnalysisLoading: false,
          isGoButtonDisabled: true
        });
      },
    );
  }

  // filterTerms = () => {
  //     this.setState({
  //       isFilterTermsLoaded:false,
  //         isGoButtonDisabled: true
  //     });
  //   const params = {logged_in: this.state.logged_in};
  //   ApiServices.AccFilterService.studentFilterTerm(params, this.state.token).then(res => res.json()).then((result) => {
  //       this.setState({
  //         isFilterTermsLoaded: true,
  //         filterTermValue:2021,
  //         filterTerms:result.data.result
  //       });
  //       this.filterSemestersFunc(result.data.result[0].id);
  //     },

  //     (error) => {
  //       this.setState({
  //         isFilterTermsLoaded: true,
  //         error
  //       });
  //     }
  //     ) ;
  //  }

  filterSemestersFunc = () => {
    // this.setState({
    //   filterStudentValue: 0,
    //   filterStudents: [],
    //   studentName: "",
    //   isGoButtonDisabled: true}, () => {
    //     this.setState({
    //       isFilterSemestersLoaded: true,
    //       filterSemesters: [{ "id": 1, "name": "Semester 01" },{ "id": 2, "name": "Semester 02" }],
    //       filterSemesterValue: 1
    //     },() => {
    //       this.changeSemesterValue(this.state.filterSemesterValue);
    //     });
    //   });
    this.setState(
      {
        isFilterStudentsLoaded: false,
        isFilterCoursesLoaded: false,
        filterStudents: [],
        filterCourses: [],
        isGoButtonDisabled: false,
      },
      () => {
        this.setState(
          {
            isFilterSemestersLoaded: true,
            filterSemesters: this.state.filterData.semesters,
            filterSemesterValue: this.state.filterData.semesters[0].id,
          },
          () => {
            this.changeSemesterValue(this.state.filterSemesterValue);
          },
        );
      },
    );
  };

  changeTermValue = (value, trigger) => {
    this.setState({ filterTermValue: value }, () => {
      this.filterSemestersFunc();
    });

    if (trigger === 'redraw') {
      this.setState({
        isFirstTimeLoad: false,
      });
    }
  };

  changeSemesterValue = (value, trigger) => {
    this.setState({ filterSemesterValue: value }, () => {
      this.filterStudents();
    });

    if (trigger === 'redraw') {
      this.setState({
        isFirstTimeLoad: false,
      });
    }
  };

  filterStudents = () => {
    this.setState({
      isFilterStudentsLoaded: false,
      isFilterCoursesLoaded: false,
      filterStudents: [],
      filterCourses: [],
      isGoButtonDisabled: false,
    });

    const { students } = this.state.filterData;

    if (students && students.length > 0) {
      this.setState(
        {
          isFilterStudentsLoaded: true,
          filterStudents: students,
          filterStudentValue: students[0].id,
        },
        () => {
          this.changeStudentValue(this.state.filterStudentValue);
        },
      );
    } else {
      this.setState(
        {
          isFilterStudentsLoaded: true,
          isStudentNameLoaded: true,
          studentName: '',
          filterStudents: [{ name: '- No Data', id: '' }],
          isGoButtonDisabled: true,
        },
        () => {
          this.setNoCoursesOnStudent();
        },
      );
    }
  };

  changeStudentValue = (value, trigger) => {
    //  value = 7250;
    this.setState(
      { filterStudentValue: value },
      () => {
        this.basicInfo();
        this.filterCourses();

        if (trigger === 'redraw') {
          this.setState({
            isFirstTimeLoad: false,
          });
        }
      },
      () => {
        if (this.state.isFirstTimeLoad) {
          this.redrawCharts();
        }
      },
    );
  };

  //  filterStudents = () => {
  //     this.setState({
  //         isFilterStudentsLoaded:false,
  //         isGoButtonDisabled: true
  //     });

  //     const params = {accountId: this.state.logged_in};
  //     ApiServices.AccFilterService.students(params, this.state.token).then(res => res.json()).then((result) => {
  //       if(helper.isObject(result) && helper.isObject(result.data) && helper.isObject(result.data.result) && result.data.result.length > 0){
  //         this.setState({
  //           isFilterStudentsLoaded: true,
  //           // filterStudentValue:result.data.result[0].id,
  //           filterStudents:result.data.result
  //         }, () => {
  //             this.changeStudentValue(result.data.result[0].id);
  //         });
  //       }else{
  //         this.setState({
  //           isFilterStudentsLoaded: true,
  //           filterStudents: [{name:"No Data", id:""}],
  //           isGoButtonDisabled: true
  //         }, () => {
  //           this.setState({
  //             isFilterCoursesLoaded: true,
  //             filterCourses: [{name: "No Data", id: 0}],
  //             isGoButtonDisabled: true,
  //             isCourseAnalysisLoaded: true,
  //           });
  //         });
  //       }
  //       },

  //       (error) => {
  //         this.setState({
  //           isFilterStudentsLoaded: true,
  //           error
  //         });
  //       }
  //       ) ;
  //  }

  filterCourses = () => {
    this.setState({
      filterCourses: [],
      isGoButtonDisabled: false,
      isFilterCoursesLoaded: false,
    });

    const {
      filterData,
      filterStudentValue,
      filterTermValue,
      filterSemesterValue
    } = this.state;

    const courses = _get(
      filterData,
      `courses[${filterStudentValue}][${filterTermValue}][${filterSemesterValue}]`,
      []
    );

    if (courses.length) {
      this.setState(
        {
          filterCourses: courses,
        },
        () => {
          this.setState(
            {
              isFilterCoursesLoaded: true,
              filterCourseValue: courses[0].id,
              isGoButtonDisabled: false,
            },
            () => {
              this.changeCourseValue(this.state.filterCourseValue);
            },
          );
        },
      );
    } else {
      this.setNoCoursesOnStudent();
    }
  };

  //  filterCourses() {
  //   this.setState({
  //     filterCourses: [],
  //     isGoButtonDisabled: false, // override GoButton disablity from here as no course filter to show the no data in causes. buttom enabled
  //     isFilterCoursesLoaded: false
  //   });

  //   const params = {logged_in: this.state.logged_in, observer: 'O'};
  //   ApiServices.AccFilterService.studentFilters(params, this.state.token).then(res => res.json()).then((result) => {
  //     // const params = { id: this.state.logged_in, role: 'O'};
  //     // ApiServices.AccFilterService.parentStudentCoursesBySemester(params, this.state.token).then(res => res.json()).then((result) => {
  //       if(helper.isObject(result) && helper.isObject(result.data)){
  //           let courses = [];
  //         try{
  //           courses = result.data.courses[this.state.filterStudentValue][this.state.filterTermValue][this.state.filterSemesterValue];
  //         }catch(e){
  //         }

  //         if(courses.length > 0){
  //           this.setState({
  //             isFilterCoursesLoaded: true,
  //             filterCourses: courses
  //           });

  //           if(this.state.isFirstTimeLoad){
  //             this.redrawCharts();
  //           }
  //         }else
  //           this.setNoCoursesOnStudent();
  //       }else
  //         this.setNoCoursesOnStudent();
  //     },
  //     (error) => {
  //       this.setState({
  //         isFilterCoursesLoaded: true,
  //         error
  //       });
  //     }
  //   );
  // }

  changeCourseValue(value) {
    this.setState({ filterCourseValue: value }, () => {
      if (this.state.isFirstTimeLoad) {
        this.setState({
          isFirstTimeLoad: false,
        });
        this.redrawCharts();
      }
    });
  }

  setNoCoursesOnStudent = () => {
    this.setState({
      isFilterCoursesLoaded: true,
      filterCourses: [{ name: '- No Data', id: 0 }],
      isCourseAnalysisLoaded: false,
      isCourseAnalysisLoading: false,
    });
  };

  redrawCharts = () => {
    this.setState(
      {
        isCourseAnalysisLoaded: false,
        filterChanged: Date.now(),
      },
      () => {
        this.fetchedItems = [];
        const { filterCourses } = this.state;
        this.props.overrideSubjectAnalysis(filterCourses);
        setTimeout(() => this.fetchItemsInViewport(), 1000);
      },
    );
  };

  basicInfo = () => {
    const { fetchStudentProfile } = this.props;
    const { token } = this.state;
    const params = {
      logged_in: this.state.logged_in,
      studentId: this.state.filterStudentValue,
    };
    fetchStudentProfile(params, token);
  };

  subjectAnalysis = () => {
    const { token, filterCourses } = this.state;
    const courseIds = filterCourses.map(course => course.id);
    const statusRole = 'PA';

    this.setState({
      isCourseAnalysisLoaded: false,
      isCourseAnalysisLoading: true,
      courseAnalysisData: [],
    });

    const params = {
      term: this.state.filterTermValue,
      courseIds: courseIds,
      studentId: this.state.filterStudentValue,
      yearLevel: this.state.filterYearLevelValue,
      status: statusRole,
      semester: this.state.filterSemesterValue,
      access: this.state.role_access,
    };

    const { fetchSubjectAnalysis } = this.props;
  
    //getting submission data
    fetchSubjectAnalysis(params, token);
  };

  drawSubjectAnalysis = () => {
    const { courseAnalysisData } = this.state;
    const mainarr = courseAnalysisData.map((course, index) => {
      return {
        chart: {
          inverted: true,
          type: 'boxplot',
          redrawOnParentResize: true,
          redrawOnWindowResize: true,
          height: '30%',
        },
        title: {
          text: '',
        },
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: course.name,
          title: {
            text: '',
          },
          labels: {
            enabled: false,
          },
        },
        plotOptions: {
          boxplot: {
            lineWidth: 2,
          },
        },
        yAxis: {
          title: {
            text: '',
          },
        },
        credits: {
          enabled: false,
        },
        exporting: { enabled: false },
        series: [
          {
            name: 'COHORT',
            color: '#002b80',
            data: course.boxplot,
          },
          {
            name: 'STUDENT',
            color: '#801100',
            type: 'scatter',
            data: [course.scatter],

            tooltip: {
              pointFormat: 'STUDENT: {point.y}',
            },
          },
        ],
      };
    });
    this.setState(
      {
        couseAnalysisChartsObjects: mainarr,
      },
      () => {
        this.setState({
          isCourseAnalysisLoaded: true,
        });
      },
    );
  };

  routeChange = () => {
    const path =
      `${process.env.PUBLIC_URL}/dashboard/student?token=${this.state.logged_in}&t=${this.state.token_with_id}&year=${this.state.filterTermValue}&semester=${this.state.filterSemesterValue}&student=${this.state.filterStudentValue}&profile=${this.state.profile}&role=O&access=${this.state.role_access}`;
    const { history } = this.props;
    history.push(path);
  };

  getRenderedHtml = value => {
    if (value === 'PENDING') return <i>{value}</i>;
    return value;
  };

  generatepdf = () => {
    const input = document.getElementById('collddd');
    html2canvas(input).then(canvas => {
      const { width, height } = canvas;
      const pdf = new jsPDF({
        orientation: width > height ? 'l' : 'p',
        unit: 'mm',
        format: [(height + 20) * 0.2645833333, width * 0.2645833333],
      });
      const imgData = canvas.toDataURL('image/png');

      pdf.addImage(imgData, 'PNG', 0, 0);
      pdf.save(
        `Parent_Profile_${moment().tz('Australia/Lindeman').format('YYYY-MM-DD hh:mm:ss')}`,
      );
    });
  };

  isFetched = itemId => this.fetchedItems.includes(itemId);

  onCollapse = (status) => {
    if (!status) {
      setTimeout(() => this.fetchItemsInViewport(),500)
    }
  }

  fetchItemsInViewport = () => {
    const { courseAnalysisData } = this.state;

    const innerHeight = window.innerHeight;
    let itemsInViewport = [];
    if (courseAnalysisData && courseAnalysisData.length) {
      courseAnalysisData.forEach(item => {
        const itemId = item.courseId1
        const element = document.getElementById(`course_${itemId}`);
        const courseName = item.name || '';

        if (!element) {
          return;
        }

        const boundingRect = element.getBoundingClientRect();
        const top = _get(boundingRect, 'top')

        // check if the item is in range
        if(top > 0 && top < innerHeight) {
          if (this.isFetched(itemId)) {
            // already fetched, no need to send the request again
            return false
          }

          this.fetchedItems.push(itemId);
          this.chunkedSubjectAnalysis([itemId], [courseName]);
        }
      });
    }

    return itemsInViewport;
  }

  chunkedSubjectAnalysis = (courseIds, courseNames) => {
    const { token } = this.state;
    const statusRole = 'PA';
    const params = {
      term: this.state.filterTermValue,
      courseIds: courseIds,
      studentId: this.state.filterStudentValue,
      yearLevel: this.state.filterYearLevelValue,
      status: statusRole,
      semester: this.state.filterSemesterValue,
      access: this.state.role_access,
      courseNames,
    };

    const { fetchSubjectAnalysis } = this.props;
    fetchSubjectAnalysis(params, token);
  }

  renderCourses = () => {
    const { isDashboardLoading } = this.props;
    const { filterCourses, couseAnalysisChartsObjects, courseAnalysisData } = this.state;

    if (isDashboardLoading) {
      return (
        <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
        >
          <img src={preloaderGif} height="100px" alt="preloader"/>
        </div>
      )
    }

    const validIds = new Set((filterCourses || []).map(item => item.id));
    const filteredAnalysisData = (courseAnalysisData || []).filter(item =>
      validIds.has(item.courseId1)
    );

    if (filterCourses && filterCourses.length > 0) {
      if (filteredAnalysisData && filteredAnalysisData.length) {
        return filteredAnalysisData.map(
          (course, index) => {
            if (course.courseId1) {
              return <CourseSection
                onCollapse={this.onCollapse}
                course={course}
                getRenderedHtml={this.getRenderedHtml}
                chartOptions={
                  _get(couseAnalysisChartsObjects, `[${index}]`, {})
                }
                key={`course_${index}`}
                filterChanged={this.state.filterChanged}
              />
            }
          }
        )
      } 
      return (
        <div className="acc-parent-profile-label">
          <h4>{"Please apply the 'GO' button."}</h4>
        </div>
      );
    }

    return (
      <div className="acc-parent-profile-label">
        <h5>{'NO DATA AVAILABLE'}</h5>
      </div>
    )
  }

  isExportButtonDisabeled = () => {
    const {isGoButtonDisabled, isSubjectsLoading, courseAnalysisData } = this.state;

    return (
      isGoButtonDisabled ||
      isSubjectsLoading ||
      courseAnalysisData.length !== this.fetchedItems.length
    );
  };

  render() {
    return (
      <Fragment>
        <div className="container-fluid acc-parentDashboard">
          <FilterSection
            filterTerms={this.state.filterTerms}
            isFilterTermsLoaded={this.state.isFilterSemestersLoaded}
            filterTermValue={this.state.filterTermValue}
            changeTermValue={this.changeTermValue}
            isFilterSemestersLoaded={this.state.isFilterSemestersLoaded}
            filterSemesters={this.state.filterSemesters}
            filterSemesterValue={this.state.filterSemesterValue}
            changeSemesterValue={this.changeSemesterValue}
            isFilterStudentsLoaded={this.state.isFilterStudentsLoaded}
            filterStudents={this.state.filterStudents}
            filterStudentValue={this.state.filterStudentValue}
            changeStudentValue={this.changeStudentValue}
            generatepdf={this.generatepdf}
            redrawCharts={this.redrawCharts}
            isGoButtonDisabled={this.state.isGoButtonDisabled}
            exportButtonDisabled={this.isExportButtonDisabeled()}
          />
          <div id="collddd">
            <div className="user-profile">
              <div className="row">
                <BannerSection
                  bannerImage={accimage}
                  isFinishedLoading={
                    this.state.isStudentNameLoaded &&
                    this.state.isFilterTermsLoaded &&
                    this.state.isFilterSemestersLoaded
                  }
                  studentName={this.state.studentName}
                  term={this.state.filterTermValue}
                  semester={this.state.filterSemesterValue}
                  showProfileButton={false}
                  gotoUser={this.routeChange}
                />

                <div className="col-xl-12 xl-100">
                  <div className="card">
                    <div className="table-head">
                      <div className="row" >
                        <div className="col-md-5 col-lg-5 ">
                          <h6 style={{ textAlign: 'left', fontWeight: 'bold' }}>
                            SUBJECT
                          </h6>
                        </div>
                        <div className="col-lg-3 col-md-3">
                          <h6 style={{ textAlign: 'center', fontWeight: 'bold' }}>
                            SUBJECT GRADE
                          </h6>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <h6 style={{ textAlign: 'right', fontWeight: 'bold' }}>
                            MARKS DISTRIBUTION
                          </h6>
                        </div>
                      </div>
                    </div>


                    <div
                      className="card-body acc-parent-content"
                      style={{ backgroundColor: 'white' }}
                    >
                      <div className="user-status table-responsive">
                        <div className="user-profile">
                          { this.renderCourses() }
                          <div
                            className="col-xl-12 xl-100"
                            style={{ margin: 'auto', textAlign: 'center' }}
                          >
                            <img
                              src={boxplotimage}
                              style={{
                                width: 'inherit',
                                maxWidth: 'min-content',
                              }}
                              alt="boxPlotImage"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapDispatchToPros = {
  fetchParentStudent,
  fetchStudentProfile,
  fetchSubjectAnalysis,
  overrideSubjectAnalysis,
};

const mapStateToProps = state => {
  return {
    filterData: state.acc.parentDashboard.filterData,
    filterTerms: state.acc.parentDashboard.filterTerms,
    isDashboardLoading: state.acc.parentDashboard.isDashboardLoading,
    currentStudent: state.acc.parentDashboard.currentStudent,
    isCurrentStudentLoading: state.acc.parentDashboard.isLoadingCurrentStudent,
    isSubjectAnalysisLoading: state.acc.parentDashboard.isLoadingSubjectAnalysis,
    subjectAnalysis: state.acc.parentDashboard.subjectAnalysis
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToPros
)(withRouter(ParentProfile));
