import React, { useState, useCallback, useEffect } from 'react';
import { Menu, Tooltip } from 'antd';
import _debounce from "lodash/debounce";
import { useSelector } from "react-redux";
import { NotificationOutlined, LockOutlined } from "@ant-design/icons";
import {
  makeInnovativeCoursesResponseData,
  makeInnovativeCourseResponseData,
  makeInnovativeCourseLoading,
  makeInnovativeSelectedCourses
} from '../../../../../selectors/innovative/course.selector';
import Search from '../../form/Search';
import useCourseHandlers from '../../../../../utils/innovative/coursehandlers';

const CourseList = () => {
  const [courses, setCourses] = useState([]);
  const courseList = useSelector(makeInnovativeCoursesResponseData);
  const selectedCourse = useSelector(makeInnovativeCourseResponseData);
  const selectedCoursesLoading = useSelector(makeInnovativeCourseLoading);
  const SelectedCourse = useSelector(makeInnovativeSelectedCourses);

  const { selectCourse } = useCourseHandlers();

  const onCourseSearch = _debounce((keyword) => {
    if (keyword) {
      const filtered = courseList.filter(course => {
        return course['course_name'].toLowerCase().includes(keyword.toLowerCase());
      });
      return setCourses(filtered);
    }

    return setCourses(courseList);
  }, 500);

  useEffect(() => {
    if (courseList) {
      setCourses(courseList);
    }
  }, [JSON.stringify(courseList)])


  const onSearch = (event) => {
    const { value } = event.target;
    onCourseSearch(value);
  }

  const onItemClick = (item) => {
    const { course } = item;
    selectCourse(course);
  }

  useEffect(() => {
    if (SelectedCourse) {
      const index = courses.findIndex(course => course.course_id === SelectedCourse);
      if (index !== -1) {
        const element = courses[index];
        const newCourses = [element, ...courses.filter(course => course.course_id !== Number(SelectedCourse))];
        setCourses(newCourses);
      }
    }
  }, [SelectedCourse]);

  return (
    <div className='course-listing'>
      <h5>Courses</h5>
      <Search onSearch={onSearch} />
      <div className="menuScroller">
        <Menu
          defaultSelectedKeys={courses && courses[0] && courses[0]["course_id"]}
          mode="inline"
        >
          {courses.map((course) => {
            const selectedMenu =
              parseInt?.(selectedCourse?.course_id) ===
              parseInt?.(course?.course_id) && !selectedCoursesLoading;

            return (
              <Menu.Item
                onClick={({ ...params }) => onItemClick({ ...params, course })}
                key={course.course_id}
                className={selectedMenu ? "selected-menu" : "normal-menu"}
                icon={<NotificationOutlined style={{marginLeft: '-20px'}}/>}
                disabled={!course.status}
              >
                <Tooltip title={course.course_name} placement='topRight'>
                  <p className="select-menu-text">{course.course_name}</p>
                </Tooltip>
                {!course.status && (
                  <Tooltip title="No permission to view" placement='topRight'>
                    <LockOutlined
                      style={{color: '#EB5757'}}
                      className="select-disable-icon"
                    />
                  </Tooltip>
                )}
              </Menu.Item>
            );
          })}
        </Menu>
      </div>
    </div>
  )
}

export default CourseList;